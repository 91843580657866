<template>
  <div class="codeID">
    <van-nav-bar title="证件照" left-arrow fixed @click-left="goBack" @click-right="isDropdownMenu= !isDropdownMenu" >
      <template #right>
                <van-icon size="22" name="apps-o"/>
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/')">
                    <span>首页</span>
                </div>
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>
    <div class="content-box">
      <div class="photo-box">
        <img class="logo-box" :src="activeList.data.filenames[0].preview_url" />
      </div>

      <van-button
        class="submit"
        round
        type="info"
        size="small"
        @click="submit('save')"
        >点击预览，长按保存到相册</van-button
      >
      <!--      <van-button class="submit" round type="info" size="small" @click="submit">邀请好友使用</van-button>-->
    </div>
  </div>
</template>

<script>
import { downLoadFree } from "@/api/photo.js";
export default {
  name: "savePhoto",
  data() {
    return {
      isDropdownMenu:false,
      activeList: {
        data: {
          filenames: [
            {
              preview_url: "",
            },
          ],
        },
        listData: {},
        orderNo: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    downApp(){
      window.location.href = 'https://www.saen.com/app/';
    },
    goBack() {
      this.$router.go(-1);
    },
    init() {
      this.activeList = JSON.parse(localStorage.getItem("detail_makePhoto"));
      this.listData = JSON.parse(localStorage.getItem("shotTypeDetail"));
      this.orderNo = localStorage.getItem("orderNo");
    },
    savePhoto(e) {
      let Url = e; //图片路径，也可以传值进来
      console.log("url", Url);
      //Url = this.imgURL //图片路径，也可以传值进来
      var triggerEvent = "touchstart"; //指定下载方式
      var blob = new Blob([""], { type: "application/octet-stream" }); //二进制大型对象blob
      var url = URL.createObjectURL(blob); //创建一个字符串路径空位
      var a = document.createElement("a"); //创建一个 a 标签
      a.href = Url; //把路径赋到a标签的href上
      //正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
      a.download = Url.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
      /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
     e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用）*/
      //代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
      var e = new MouseEvent(
        "click",
        (true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      );
      //派遣后，它将不再执行任何操作。执行保存到本地
      a.dispatchEvent(e);
      //释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
      URL.revokeObjectURL(url);
    },
    submit() {
       this.$router.push({
          path: "/downloadPhoto",
        });
      
     
    },
  },
};
</script>

<style lang="less" scoped>
.downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            right: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 100px;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
.codeID {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;

  .content-box {
    width: 100%;
    height: calc(100vh - 46px);
    margin: 46px auto 0;
    padding: 20px 0;

    .photo-box {
      width: 178px;
      margin: 0 auto 20px;
      position: relative;

      img {
        display: block;
        width: 178px;
        height: 200px;
      }
    }

    .submit {
      width: 80%;
      display: block;
      margin: 10px auto;
    }
  }
}
</style>
